@import '@aurora/shared-client/styles/_variables.pcss';

.lia-occasion-progress-badge {
  display: flex;
  align-items: center;

  @media (--lia-breakpoint-down-md) {
    flex-direction: column;
    align-items: flex-start;
    row-gap: 5px;
  }
}
.lia-badge {
  display: flex;
  align-items: center;
  flex-shrink: 0;
  column-gap: 8px;
  color: var(--lia-bs-black);
  font-size: var(--lia-bs-font-size-sm);
  font-weight: var(--lia-font-weight-md);
  text-transform: capitalize;
  user-select: none;
  cursor: default;

  .lia-icon {
    fill: var(--lia-bs-black);
  }
  &.lia-is-imminent-or-ongoing,
  &.lia-has-ended {
    padding: 2px 8px;
    border-radius: 100vw;
    font-size: var(--lia-font-size-xs);
  }
  &.lia-is-imminent-or-ongoing {
    background-color: var(--lia-occasion-color);
    color: var(--lia-d-occasion-text-color);

    .lia-icon {
      fill: var(--lia-d-occasion-text-color);
    }
  }
  &.lia-has-ended {
    background-color: var(--lia-bs-gray-300);
  }
}
